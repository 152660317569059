.chart-crosshair {
    background-color: #12939A;
    white-space: nowrap;
    padding: 5px;
    padding-bottom: 1px;
    z-index: 100000;
    position: relative;
  }
  
  .chart-crosshair h3 {
    color: white;
  }
  
  .chart-crosshair p {
    color: white;
  }

  .chart-legend{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  .chart-legend.selected-0 .rv-discrete-color-legend-item:nth-child(1){
    font-weight: bold;
    text-decoration: underline;
  }
  
  .chart-legend.selected-1 .rv-discrete-color-legend-item:nth-child(2){
    font-weight: bold;
    text-decoration: underline;
  }
  
  .chart-legend.selected-2 .rv-discrete-color-legend-item:nth-child(3){
    font-weight: bold;
    text-decoration: underline;
  }

  .chart-legend.selected-3 .rv-discrete-color-legend-item:nth-child(4){
    font-weight: bold;
    text-decoration: underline;
  }
  .chart-legend.selected-4 .rv-discrete-color-legend-item:nth-child(5){
    font-weight: bold;
    text-decoration: underline;
  }
  
  .chart-legend.selected-5 .rv-discrete-color-legend-item:nth-child(6){
    font-weight: bold;
    text-decoration: underline;
  }

  .chart-legend.selected-6 .rv-discrete-color-legend-item:nth-child(7){
    font-weight: bold;
    text-decoration: underline;
  }

  .chart-legend.selected-7 .rv-discrete-color-legend-item:nth-child(8){
    font-weight: bold;
    text-decoration: underline;
  }

  .chart-legend.selected-8 .rv-discrete-color-legend-item:nth-child(9){
    font-weight: bold;
    text-decoration: underline;
  }

  .chart-legend.selected-9 .rv-discrete-color-legend-item:nth-child(10){
    font-weight: bold;
    text-decoration: underline;
  }

  .chart-legend.selected-10 .rv-discrete-color-legend-item:nth-child(11){
    font-weight: bold;
    text-decoration: underline;
  }

  .chart-legend.selected-11 .rv-discrete-color-legend-item:nth-child(12){
    font-weight: bold;
    text-decoration: underline;
  }

  .chart-legend.selected-12 .rv-discrete-color-legend-item:nth-child(13){
    font-weight: bold;
    text-decoration: underline;
  }