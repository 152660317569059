.title-container {
  display: flex;
  justify-content: space-between;
}

.sub-title-container {
    font-weight: bold;
    margin: 2em 0 1em 0;
}
.summary-table {
  font-weight: bold;
  table-layout: auto;
  border-collapse: collapse;
}

.align-right {
  text-align: right;
  white-space: nowrap;
}
.footer-content{
    display: flex;
    justify-content: center;
    gap: 1em;
}
