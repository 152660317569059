@import "./variables.scss";

main {
  .ant-card-head {
    padding: 0 12px;
  }

  .ant-card-body {
    padding: 12px;
  }
}

.centered,
.ant-spin.centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.fullsize-tabs {
  height: 100%;

  .ant-tabs-content-holder {
    position: relative;
  }

  .ant-tabs-content-holder .ant-tabs-content {
    height: 100%;
  }
}

.ant-input-number.align-right input {
  text-align: right;
  padding: 0 1em;
}

.ant-input.align-right {
  text-align: right;
  padding: 0 1em;
}

.leaflet-draw-edit-edit.leaflet-disabled,
.leaflet-draw-edit-remove.leaflet-disabled {
  display: none;
}

.make-badge-full-width .ant-badge {
  width: 100%;
}

.ant-modal.hide-close-x .ant-modal-close-x {
  display: none;
}

.ant-modal.with-custom-title .ant-modal-header {
  padding: 0;

  .custom-title {
    padding: 16px 24px;
  }
}

.ant-modal.with-custom-body .ant-modal-body {
  padding: 0;

  .custom-body {
    padding: 24px;
  }
}

.ant-modal.with-custom-footer .ant-modal-footer {
  padding: 0;

  .custom-footer {
    padding: 10px 16px;
  }
}

.ant-modal .ant-tabs .ant-tabs-tabpane {
  overflow-y: auto;
}

.anticon.primary-icon {
  color: $primary-color;
}

body td.ant-table-column-sort {
  background-color: white;
}

.ant-table-wrapper.with-custom-title .ant-table .ant-table-title {
  padding: 0;

  .custom-title {
    padding: 16px 16px;
    background-color: #fafafa;
  }
}

.anticon.clickable {
  color: white;

  &:hover {
    color: $primary-color;
  }
}

.ant-picker.ant-picker-range {
  input {
    text-align: center;
  }
}

.icon-left-text-right {
  span:not(.anticon) {
    text-align: right;
    width: calc(100% - 22px);
  }
}

.custom-tree .anticon {
  font-size: 18px;
}

