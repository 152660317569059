.actions-menu button {
  width: 100%;
}

.property-list {
  display: grid;

  &.one {
    grid-template-columns: auto;
  }

  &.two {
    grid-template-columns: auto auto;
  }
}

main .screen-with-breadcrumb {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: 0.5em;
  padding-top: 0.5em;
  height: 100%;

  .ant-tabs-tabpane-active {
    overflow: auto;
  }
}

main .screen {
  display: grid;
  grid-row-gap: 1em;
  height: 100%;
}

.multi-button-container button {
  border-radius: 0;
}

.hide-leaflet-draw .leaflet-draw-section {
  display: none;
}

.screen-with-filter-header {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}
