.project-container {
  display: flex;
  width: 100%;
  height: 100%;
}
.column-one {
  flex: 0 0 35em;
}
.column-two {
  flex: 1;
}
