$default-icon-size: 22px;
$button-icon-size: 14px;

$grey-color: #b7b3b3;
$green-color: #73d13d;
$red-color: #ff4d4f;
$black-color: #000000;


.anticon {
  font-size: $default-icon-size;
}

.ant-timeline-item .anticon {
  font-size: 16px;
  transform: translateY(-2px);
}

.ant-tag .anticon {
  font-size: 16px;
  transform: translateY(1px);
}

button,
.button-like,
.button-like-icon-size,
.ant-input-suffix {
  .anticon {
    font-size: $button-icon-size;
  }
}

.ant-dropdown-trigger .anticon.anticon-down {
  font-size: $button-icon-size;
  transform: translateY(1px);
}

.ant-dropdown .anticon.anticon-down {
  font-size: $button-icon-size;
}

.ant-select .anticon {
  font-size: $button-icon-size;
}

.ant-input-suffix .anticon {
  color: #d9d9d9;
}

.ant-table-column-sorter-inner {
  .anticon {
    font-size: 11px;
  }
}

.icon {
  color: $grey-color;
  position: relative;
  top: calc(50% - 11px);
  margin: 0 5px 0 5px;
}

.icon-drawer {
  @extend .icon;
  font-size: 18px;
}

.icon-drawer-save {
  @extend .icon-drawer;
  color: $green-color; 
}

.icon-drawer-save {
  @extend .icon-drawer;
  color: $green-color; 
}
.icon-drawer-cancel {
  @extend .icon-drawer;
  color: $red-color; 
}

.icon-bold {
  @extend .icon;
  color: $black-color;
  font-weight: bold;
}
